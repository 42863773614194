import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import limitWarning from "components/assets/utils/images/plan/limit-warning.svg";
import { useMutation, useQuery } from "@tanstack/react-query";
import Input from "components/inputs/Input";
import Radio from "components/inputs/Radio";
import Select from "components/inputs/Select";
import Switch from "components/inputs/Switch";
import { Form, Formik } from "formik";

import { Button, Card, Col, Container, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { countries } from "utils/api/countries";
import users from "utils/api/users";
import EMPLOYMENTTYPE from "utils/enums/EmploymentType";
import Gender from "utils/enums/Gender";
import {
  arabicValue,
  errorsToFormikErrors,
  isRole,
} from "utils/functions/utilities";
import { useUser } from "utils/hooks/auth";
import { AccountFormInitialValues, AccountFormValidation } from "./validation";
import { toast } from "react-toastify";
import { useMemo, useState } from "react";
import AccountFormUploads from "./Files";
import Role from "utils/enums/Role";

type Props = {
  nextStep: () => void;
  hideSubmit?: boolean;
  Header?: any;
  submitTitle?: string;
  uploads?: boolean;
  callback?: (val: any) => void;
};

const AccountForm = (props: Props) => {
  const {
    Header,
    submitTitle,
    hideSubmit = false,
    nextStep,
    callback,
    uploads,
  } = props;
  const [showWarning, setShowWarning] = useState(false);
  const { t } = useTranslation();
  const { data: user } = useUser({});
  const updateMutation = useMutation((payload) => {
    callback && callback(payload);
    return users.update(user!.id, payload);
  });

  const { data: countriesData } = useQuery(["countries"], countries.list, {
    initialData: [],
    select: (data) =>
      data.map((country: any) => ({
        value: country.id,
        label: arabicValue(country, "name"),
      })),
  });

  async function submit(values: any, formikBag: any) {
    if (!values.confirmed && isRole(Role.USER, user)) {
      setShowWarning(true);
      return;
    }

    updateMutation.mutate(values, {
      onSuccess: (data: any) => {
        toast.success(data.message);
        nextStep();
      },
      onError: (errors: any) => {
        errorsToFormikErrors(errors.errors, formikBag);
      },
    });
  }

  const renderTooltipTitle = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("common:current_obligations_tip")}
    </Tooltip>
  );

  const renderTooltipInput = (props: any) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("common:sum_of_monthly_installments_tip")}
    </Tooltip>
  );

  return (
    <Container className="px-0 mb-2">
      <Formik
        enableReinitialize
        initialValues={{
          ...AccountFormInitialValues,
          ...user,
          has_loans: user?.monthly_loans ? true : false,
        }}
        validationSchema={useMemo(
          () => AccountFormValidation(user?.role_id),
          [user?.role_id]
        )}
        onSubmit={submit}
      >
        {(props) => (
          <Form>
            <SalaryLimitWarning
              user={user}
              formikBag={props}
              show={showWarning}
              hide={() => setShowWarning(false)}
              percentage={40}
            />
            {Header && <Header />}
            <Card className="mb-4">
              <Card.Header className="fw-semibold">
                {t("common:personal")}
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col lg={12} className="mb-4">
                    <Input
                      name="name"
                      label={t("common:legal_name")}
                      placeholder={t("common:legal_name_as_on_id")}
                    />
                  </Col>
                </Row>
                <Row>
                  <p className="fs-7 fw-semibold">{t("gender")}</p>
                  <Col lg={6} className="my-2">
                    <Radio
                      name="gender"
                      value={Gender.MALE}
                      label={`👦 ${t("male")}`}
                    />
                  </Col>
                  <Col lg={6} className="my-2">
                    <Radio
                      name="gender"
                      value={Gender.FEMALE}
                      label={`👧 ${t("female")}`}
                    />
                  </Col>
                  <Col lg={6} className="my-2">
                    <Input
                      placeholder={t("auth:email_here")}
                      name="email"
                      label={t("email")}
                    />
                  </Col>
                  <Col lg={6} className="my-2">
                    <Input
                      placeholder={t("plans:enter_civil_id")}
                      name="personal_id"
                      label={t("plans:civil_id")}
                    />
                  </Col>
                  <Col lg={4} className="my-2">
                    <Select
                      name="marital_status"
                      label={t("plans:marital_status")}
                      placeholder={t("plans:choose_marital_status")}
                      labelKey="label"
                      options={[
                        { value: "Married", label: t("common:married") },
                        { value: "Divorced", label: t("common:divorced") },
                        { value: "Single", label: t("common:single") },
                        { value: "Widowed", label: t("common:widowed") },
                      ]}
                    />
                  </Col>
                  <Col lg={4} className="my-2">
                    <Input
                      type="date"
                      name="birthday"
                      label={t("plans:date_of_birth")}
                    />
                  </Col>
                  <Col lg={4} className="my-2">
                    <Select
                      options={countriesData}
                      name="nationality_id"
                      label={t("plans:nationality")}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            {isRole(Role.USER, user) && (
              <>
                <Card className="mb-4">
                  <Card.Header className="fw-semibold">
                    {t("plans:employment")}
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <p className="fs-7 fw-semibold">
                        {t("plans:employment_status")}
                      </p>
                      <Col lg={4} className="my-2">
                        <Radio
                          name="employment_type"
                          value={EMPLOYMENTTYPE.SALARIED}
                          label={t("plans:salaried")}
                        />
                      </Col>
                      <Col lg={4} className="my-2">
                        <Radio
                          name="employment_type"
                          value={EMPLOYMENTTYPE.SELFEMPLOYED}
                          label={t("plans:self_employed")}
                        />
                      </Col>
                      <Col lg={4} className="my-2">
                        <Radio
                          name="employment_type"
                          value={EMPLOYMENTTYPE.RETIRED}
                          label={t("plans:retired")}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} className="my-2">
                        <Input
                          placeholder={t("plans:company_name")}
                          name="company"
                          label={t("plans:company")}
                        />
                      </Col>
                      <Col lg={6} className="my-2">
                        <Select
                          labelKey="label"
                          options={[
                            { value: "Public", label: t("common:public") },
                            { value: "Private", label: t("common:private") },
                          ]}
                          name="sector"
                          label={t("plans:sector")}
                          placeholder={t("plans:choose_sector")}
                        />
                      </Col>
                      <Col lg={6} className="my-2">
                        <Input
                          label={t("plans:work_experience_at_company")}
                          name="work_experience"
                          id="work_experience"
                          placeholder={t("plans:experience_in_years")}
                        />
                      </Col>
                      <Col lg={6} className="my-2">
                        <Input
                          label={t("plans:your_monthly_salary")}
                          name="salary"
                          id="salary"
                          placeholder={t("plans:enter_salary")}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                <Card className="mb-4">
                  <Card.Header className="fw-semibold">
                    {t("plans:loans")}
                  </Card.Header>
                  <Card.Body>
                    <Row>
                      <Col lg={10} className="my-2 d-flex align-items-center">
                        <p>{t("plans:are_you_any_loans")}</p>
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={renderTooltipTitle}
                        >
                          <i className="text-gray opacity-75 mx-2 bi bi-info-circle"></i>
                        </OverlayTrigger>
                      </Col>
                      <Col sm={12} lg={2} className="my-2">
                        <Switch
                          placeholder={t("plans:company_name")}
                          name="has_loans"
                        />
                      </Col>
                    </Row>
                    {/* @ts-ignore */}
                    {!!props.values?.has_loans && (
                      <Row>
                        <Col lg={7} className="my-2 d-flex align-items-center">
                          <p>{t("plans:what_is_total_loans")}</p>
                          <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={renderTooltipInput}
                          >
                            <i className="text-gray opacity-75 mx-2 bi bi-info-circle"></i>
                          </OverlayTrigger>
                        </Col>
                        <Col lg={5} className="my-2">
                          <Input
                            placeholder={t("plans:enter_monthly_loans")}
                            name="monthly_loans"
                          />
                        </Col>
                      </Row>
                    )}
                  </Card.Body>
                </Card>

                {/* {uploads && (
              <Card>
                <Card.Header className="fw-semibold">
                  {t("plans:upload_documents")}
                </Card.Header>
                <Card.Body>
                  <AccountFormUploads />
                </Card.Body>
              </Card>
            )} */}
              </>
            )}

            {!hideSubmit && (
              <Row className="mt-4">
                <Col
                  lg={12}
                  className="d-flex justify-content-center justify-content-lg-end"
                >
                  <Button
                    type="submit"
                    size="lg"
                    className="w-100 w-lg-auto px-6 py-3 rounded-2"
                  >
                    {submitTitle || t("submit")}
                  </Button>
                </Col>
              </Row>
            )}
          </Form>
        )}
      </Formik>
    </Container>
  );
};

function SalaryLimitWarning(props: any) {
  const { formikBag, percentage, user, show, hide } = props;
  const { t } = useTranslation(["plans", "common"]);

  return (
    <Modal show={show} size="lg" onHide={hide}>
      <Modal.Header className="border-0" closeButton></Modal.Header>
      <Modal.Body>
        <Container className="text-center">
          <Row>
            <Col lg={12} className="my-2">
              <img src={limitWarning} />
            </Col>
            <Col lg={12} className="my-2">
              <h4 className="text-dark">{t("common:maximum_monthly")}</h4>
              <div>
                <h4 className="text-dark">
                  {(formikBag.values.salary * (percentage / 100)).toFixed(0)}{" "}
                  {t("kd")}
                </h4>
              </div>
              <p>{t("based_on_your_commitments")}</p>
            </Col>
            <Col lg={12} className="my-2">
              <Button
                onClick={() => {
                  hide();
                  formikBag.setFieldValue("confirmed", true);
                  formikBag.submitForm();
                }}
                size="lg"
                className="w-100 py-3"
              >
                {t("common:understood_proceed")}
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default AccountForm;
