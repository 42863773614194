enum TransactionStatus {
  PENDING = "Pending",
  SUCCESS = "Success",
  SETTLED = "Settled",
  MANUALLY_SETTLED = "Manually Settled",
  FAILED = "Failed",
  PENDING_REFUND = "Pending Refund",
  REFUNDED = "Refunded",
}

export default TransactionStatus;