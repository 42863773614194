import { Col, Row } from "react-bootstrap";
import ModeratorPlanUpdateStatus from "./ModeratorActions/UpdateStatus";

const PlanModeratorActions = (props: { plan: any }) => {
  const { plan } = props;

  return (
    <Row>
      <Col lg={12}>
        <ModeratorPlanUpdateStatus plan={plan} />
      </Col>
    </Row>
  );
};

export default PlanModeratorActions;
