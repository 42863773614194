import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import Loader from "react-loaders";
import LoadingOverlay from "react-loading-overlay-ts";
import { Bar, BarChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import dashboard from "utils/api/dashboard";

const OnboardedSchoolsWidget = () => {
  const [filter, setFilter] = useState<any>(
    DateTime.now().minus({ years: 1 }).toJSDate()
  );
  const [filtered, setFiltered] = useState({
    total: 0,
    data: [],
  });
  const { data, isFetching } = useQuery(
    ["onboarded-schools", filter],
    () => dashboard.onboardedSchools(filter),
    {
      onSuccess: (data) => {
        let result: any = {
          total: 0,
          data: [],
        };
        data.forEach((item: any) => {
          result.total += item.total;
          result.data.push({
            name: item.namedPeriod,
            value: item.total,
          });
        });
        setFiltered(result);
      },
    }
  );

  const { t } = useTranslation(["plans", "common", "schools"]);

  return (
    <Col lg={6} className="mb-3">
      <LoadingOverlay
        active={isFetching}
        styles={{
          overlay: (base) => ({
            ...base,
            background: "#fff",
            opacity: 0.5,
          }),
        }}
        spinner={<Loader active type="ball-pulse" />}
      >
        <Card>
          <Card.Body className="p-4">
            <Row>
              <Col lg={6} className="fs-6 text-graycool fw-semibold mb-3">
                <p>{t("schools:onboarded_schools")}</p>
              </Col>
              {/* <Col lg={6} className="d-flex align-items-center">
                <Form.Control
                  size="sm"
                  type="date"
                  value={filter}
                  onChange={(value) => setFilter(value)}
                />
              </Col> */}

              {/* <p className="text-gray fw-semibold fs-7">
              From: {DateTime.fromJSDate(filter).toFormat(DATE_FORMAT)}
            </p> */}
              <Col lg={12} className="mb-5">
                <p className="text-dark fs-3 fw-bold">
                  <CountUp
                    start={0}
                    end={filtered.total ?? 0}
                    separator=""
                    decimals={0}
                    decimal=","
                    prefix=""
                    useEasing={false}
                    suffix=""
                    duration={0.3}
                  />
                  <sup className="fs-5"> {t("schools")}</sup>
                </p>
              </Col>
              <Col lg={12} style={{ height: "170px" }}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart barCategoryGap={20} data={filtered.data}>
                    <XAxis fontSize={12} dataKey="name" />
                    <Tooltip/>
                    <Bar dataKey="value" fill="#1570EF" />
                  </BarChart>
                </ResponsiveContainer>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </LoadingOverlay>
    </Col>
  );
};

export default OnboardedSchoolsWidget;
