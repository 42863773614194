import client from "../api";

export function list({page = 1, perPage = 10}) {
  return client.get(`logs?page=${page}&perPage=${perPage}`);
}


export function view(id?:any){
  return () => client.get(`logs/${id}`) as any;
}

export function destroy(id:string){
  return client.delete(`logs/${id}`) as any;
}

export const logs = { list,view,destroy };
