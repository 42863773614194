import React from "react";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Role from "utils/enums/Role";
import { useUser } from "utils/hooks/auth";
import fis from "utils/api/fis";
import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { isRole } from "utils/functions/utilities";
import dashboard from "utils/api/dashboard";
import Select from 'react-select';
import Icon from "components/widgets/Icon";
import NotificationsWidget from "../Notifications";

type Props = {};

const WelcomeWidget = (props: Props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const fi_id = queryParams.get("fi_id");

  const { data: user } = useUser();
  const { data: data } = useQuery(["dashboard", {
    endPoint: "filterables"
  }], dashboard.filterables, {
    placeholderData: {
      fis: [],
      schools: [],
    },
    enabled: isRole(Role.ADMIN, user),
  });

  const { t } = useTranslation(["common", "plans"]);
  let desc: any = t("plans:streamline_educational_financing");
  let name = user?.name;
  switch (user?.role_id) {
    case Role.ADMIN:
      name = t("common:dashboard");
      desc = null;
      break;
    case Role.FI:
      name = user.fis?.[0].name;
      desc = t("common:control_one_place");
      break;
    case Role.SCHOOL:
      name = user.schools?.[0].name;
      desc = t("common:onestop_overview_school");
  }
  return (
    <Row>
      <Col lg={4}>
        <h4 className="text-dark fw-bold">
          {t("welcome_name", {
            name,
          })}
        </h4>
        <h5 className="fw-light text-seocndary">{desc}</h5>
      </Col>

      <Col lg={8}>
        {isRole(Role.ADMIN, user) && (
          <Row>
            <Col lg={3}>
              <Select
                className="react-select me-2"
                classNamePrefix="select"
                placeholder="Select Year"
                value={data?.current_year?.find((s: any) => s.value == queryParams.get("current_year"))}
                options={data?.current_year}
                onChange={(e) => {
                  if (e != null) {
                    // Add or update the year parameter
                    const url = new URL(window.location.href);
                    url.searchParams.set('current_year', e.value);
                    window.location.href = url.toString();
                  } else {
                    // Remove the year parameter
                    const url = new URL(window.location.href);
                    url.searchParams.delete('current_year');
                    window.location.href = url.toString();
                  }
                }}
                isClearable
              />
            </Col>
            <Col lg={4}>
              <Select
                className="react-select me-2"
                classNamePrefix="select"
                placeholder="Select FI"
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.id}
                value={data?.fis?.find((s: any) => s.id == queryParams.get("fi_id"))}
                options={data?.fis}
                onChange={(e) => {
                  if (e != null) {
                    // Add or update the fi_id parameter
                    const url = new URL(window.location.href);
                    url.searchParams.set('fi_id', e.id);
                    window.location.href = url.toString();
                  } else {
                    // Remove the fi_id parameter
                    const url = new URL(window.location.href);
                    url.searchParams.delete('fi_id');
                    window.location.href = url.toString();
                  }

                }}

                isClearable
              />
            </Col>
            <Col lg={4}>
              <Select
                className="react-select"
                classNamePrefix="select"
                placeholder="Select School"
                getOptionLabel={(option: any) => option.name}
                getOptionValue={(option: any) => option.id}
                value={data?.schools?.find((s: any) => s.id == queryParams.get("school_id"))}
                options={data?.schools}
                onChange={(e) => {
                  if (e != null) {
                    // Add or update the school_id parameter
                    const url = new URL(window.location.href);
                    url.searchParams.set('school_id', e.id);
                    window.location.href = url.toString();
                  } else {
                    // Remove the school_id parameter
                    const url = new URL(window.location.href);
                    url.searchParams.delete('school_id');
                    window.location.href = url.toString();
                  }

                }}

                isClearable
              />
            </Col>
            <Col lg={1}>
              <NotificationsWidget />
            </Col>

          </Row>
        )}
      </Col>
      <Col lg={12} className="mb-4">
        <hr style={{ height: "1px" }} className="bg-light opacity-50" />
      </Col>
    </Row>
  );
};

export default WelcomeWidget;
