import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import TransactionStatus from "utils/enums/TransactionStatus";
import { useUser } from "utils/hooks/auth";

function TransactionStatusRenderer(props) {
  const { t } = useTranslation(["plans", "common"]);
  const { due_date, status } = props;
  const today = DateTime.local().startOf("day");
  const date = DateTime.fromISO(due_date).startOf("day");
  const itsToday = today.toMillis() === date.toMillis();
  const overDue = today.toMillis() > date.toMillis();;
  let label = t("common:pending");

  let color = "gray";
  if (status === TransactionStatus.SUCCESS) {
    color = "success";
    label = t("common:paid");
  } else if (status === TransactionStatus.SETTLED) {
    color = "success";
    label = t("common:settled");
  } else if (status === TransactionStatus.MANUALLY_SETTLED) {
    color = "success";
    label = t("common:manually_settled");
  } else if (status === TransactionStatus.PENDING_REFUND) {
    color = "warning";
    label = t("common:refund_requested");
  } else if (status === TransactionStatus.REFUNDED) {
    label = t("common:refunded");
  } else if (overDue) {
    color = "danger";
    label = t("overdue");
  } else if (itsToday) {
    color = "primary";
    label = t("due_today");
  }

  return (
    <div className="widget-content-right">
      <div className={`badge bg-${color}`}>{label}</div>
    </div>
  );
}

export default TransactionStatusRenderer;
