import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import logo from "components/assets/utils/images/logo.png";
import DashboardPlansActions from "containers/Plans/List/Actions";
import Filters from "containers/Plans/List/components/Filters";
import PlanListCard from "containers/Plans/List/components/PlanListCard";
import PlansDownloader from "containers/Plans/List/components/PlansDownloader";
import plansTablecolumns, {
  columnOrder,
  plansTablecolumnVisibility,
} from "containers/Plans/List/tableConfig";
import PlanMissingDocumentsActionsForm from "containers/Plans/View/Actions/UserActions/PlanMissingDocumentsActionsForm";
import RoleBasedRenderer from "containers/common/RoleBasedRenderer";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import { Alert, Button, Card, Col, Row, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { plans } from "utils/api/plans";
import { transactions } from "utils/api/transactions";
import { breakPoints } from "utils/constants";
import PlanStatus from "utils/enums/PlanStatus";
import Role from "utils/enums/Role";
import { isRole, paymentIsDone } from "utils/functions/utilities";
import { useUser } from "utils/hooks/auth";
import { usePaginate } from "utils/hooks/paginate";
import useWindowDimensions from "utils/hooks/window";

const pendingOrIncompleteStatuses = [
  PlanStatus.INCOMPLETE,
  PlanStatus.PENDING_DOCUMENTS,
  PlanStatus.PENDING_RUBA,
  PlanStatus.MISSING_DOCUMENTS_RUBA,
  PlanStatus.PENDING_QUOTATION,
  PlanStatus.PENDING_SCHOOL_APPROVAL,
  PlanStatus.PENDING_FI,
  PlanStatus.MISSING_DOCUMENTS_FI,
  PlanStatus.PENDING_CINET,
  PlanStatus.PENDING_SERVICE_FEE,
  PlanStatus.PENDING_SIGNATURE,
  PlanStatus.PENDING_RECEIPT,
  PlanStatus.PENDING_PAYMENT,
  PlanStatus.PENDING_PAYMENT_SCHOOL,
  PlanStatus.PENDING_INVOICE,
  PlanStatus.PENDING_RUBA_TID,
  PlanStatus.PENDING_RUBA_SCHOOL_TID
];

const NewPlansWidget = () => {
  const [filter, setFilter] = useState({});
  const { t } = useTranslation(["plans", "common", "auth"]);
  const { data: user } = useUser();
  const navigate = useNavigate();

  const paginate = usePaginate({
    key: "plans",
    api: plans.list,
    filter,
    options: {
      onSuccess: (data: any) => {
        if (data.data?.length == 0 && isRole(Role.USER, user)) {
          navigate("/plans/new");
        }
      },
    },
  });

  const { data: transactionData } = usePaginate({
    key: "unsettled_transactions",
    api: transactions.list,
    options: {
      placeholderData: [],
      select: ({ data }: any) => {

        const pending = data?.filter((item: any) => DateTime.fromISO(item.scheduled_date) < DateTime.now())
          .filter((item: any) => !paymentIsDone(item));
        return pending;
      },
    },
  });


  const { width } = useWindowDimensions();

  const table = useReactTable({
    data: paginate?.data?.data,
    columns: useMemo(() => plansTablecolumns(t, user), [t]),
    getCoreRowModel: getCoreRowModel(),
    state: {
      columnOrder: useMemo(() => columnOrder(user?.role_id!), [width]),
      columnVisibility: useMemo(
        () => plansTablecolumnVisibility(width, user?.role_id!),
        [width]
      ),
    },
  });

  let hasOverdue: any = transactionData?.length > 0 ?? false;
  let hasIncomplete: any = false;
  let hasMissingDocuments: any = false;
  let reapply: boolean = false;

  if (isRole(Role.USER, user)) {
    hasIncomplete = paginate?.data?.data?.find((plan: any) =>
      [PlanStatus.INCOMPLETE, PlanStatus.PENDING_DOCUMENTS].includes(
        plan.status
      )
    );
    hasMissingDocuments = paginate?.data?.data?.find((plan: any) =>
      [
        PlanStatus.MISSING_DOCUMENTS_RUBA,
        PlanStatus.MISSING_DOCUMENTS_FI,
      ].includes(plan.status)
    );
    if (!hasIncomplete && !hasMissingDocuments) {
      const hasComplete = paginate?.data?.data?.some((plan: any) => {
        if (plan.status === PlanStatus.COMPLETE) {
          return true;
        }
        if (plan.status === PlanStatus.ACTIVE) {
          const paymentDate = DateTime.fromFormat(plan.payment_due, "yyyy-MM-dd").plus({
            months: plan.number_of_installments
          });

          const twoWeeksFromNow = DateTime.now().minus({ weeks: 2 });
          const now = DateTime.now();
          return paymentDate >= twoWeeksFromNow && paymentDate <= now;
        }
        return false;
      });
      const hasPendingOrIncomplete = paginate?.data?.data.some((plan: any) => pendingOrIncompleteStatuses.includes(plan.status));

      reapply = hasComplete && !hasPendingOrIncomplete;
    }
  }

  return (
    <>
      <RoleBasedRenderer
        user={
          <>
            <Col lg="12">
              <Row>
                <Col lg={8} className="mb-2">
                  <h4 className="text-dark fw-semibold">{t("all_plans")}</h4>
                  <p className="text-gray">{t("manage_and_track")}</p>
                </Col>
                <Col lg={4} className="d-flex justify-content-end">
                  <Link to="/plans/new" className="w-100 w-sm-auto">
                    <Button
                      size="lg"
                      color="primary"
                      id="create-plan"
                      className="w-100 w-sm-auto my-2"
                    >
                      {t("create_new_plan")}
                    </Button>
                  </Link>
                </Col>
              </Row>
              {hasOverdue && (
                <Alert variant="danger" className="mb-4 mt-3">
                  <Row>
                    <Col
                      lg={9}
                      className="d-flex justify-content-center justify-content-sm-start align-items-center mb-3 mb-lg-0"
                    >
                      <p>{t("overdue_payment_desc")}</p>
                    </Col>
                    <Col lg={3} className="text-end">
                      <Button
                        onClick={() => navigate(`/installments`)}
                        variant="danger"
                        className="w-100"
                      >
                        {t("pay_now")}
                      </Button>
                    </Col>
                  </Row>
                </Alert>
              )}
              {reapply && (
                <Alert variant="info" className="mb-4 mt-3">
                  <Row>
                    <Col
                      lg={9}
                      className="d-flex justify-content-center justify-content-sm-start align-items-center mb-3 mb-lg-0"
                    >
                      <p>{t("reapply_now_desc")}</p>
                    </Col>
                    <Col lg={3} className="text-end">
                      <Button
                        onClick={() => navigate(`/plans/new`)}
                        variant="info"
                        className="w-100"
                      >
                        {t("reapply_now")}
                      </Button>
                    </Col>
                  </Row>
                </Alert>
              )}
              {hasIncomplete && (
                <Alert variant="info" className="mb-4 mt-3">
                  <Row>
                    <Col
                      lg={9}
                      className="d-flex justify-content-center justify-content-sm-start align-items-center mb-3 mb-lg-0"
                    >
                      <p>{t("have_incomplete_plan")}</p>
                    </Col>
                    <Col lg={3} className="text-end">
                      <Button
                        onClick={() => navigate(`/plans/${hasIncomplete.id}/edit`)}
                        variant="info"
                        className="w-100"
                      >
                        {t("continue_plan")}
                      </Button>
                    </Col>
                  </Row>
                </Alert>
              )}
              {hasMissingDocuments && (
                <Alert variant="info" className="mb-4 mt-3">
                  <div className="d-flex justify-content-between flex-wrap align-items-center">
                    <div className="d-flex mb-3">
                      <div className="flex-shrink-0">
                        <img width={46} className="rounded-circle" src={logo} />
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <p className="mb-2 fw-bold">
                          Ruba{" "}
                          <span className="fbs-4 fw-normal text-dark">
                            {DateTime.fromISO(
                              hasMissingDocuments.updated_at
                            ).toRelative()}
                          </span>
                        </p>
                        <p className="text-dark opacity-75">
                          {t("have_missing_document_plan")}
                        </p>
                      </div>
                    </div>
                    <div className="w-100 w-sm-auto">
                      <PlanMissingDocumentsActionsForm
                        plan={hasMissingDocuments}
                        alert
                      />
                    </div>
                  </div>
                </Alert>
              )}
            </Col>

          </>
        }
      />
      <Col lg="12">
        {width > breakPoints.md ? (
          <Card className="mb-3">
            <Card.Header>
              <Row className="justify-content-between">
                <Col md="2">
                  <Card.Title>
                    <h5>{t("new_plans")}</h5>
                  </Card.Title>
                </Col>
                <Col md="8">
                  <Filters filter={filter} setFilter={setFilter} />
                </Col>
                <RoleBasedRenderer
                  admin={
                    <Col md="2">
                      <PlansDownloader />
                    </Col>
                  }
                />
              </Row>
            </Card.Header>
            <Card.Body className="p-0">
              <Table className="mb-0">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                        </th>
                      ))}
                      <th>{t("actions")}</th>
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                      <td>
                        <div className="d-flex">
                          <DashboardPlansActions item={row.original} />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        ) : (
          <Row>
            {paginate?.data?.data.map((plan: any) => (
              <PlanListCard key={plan.id} plan={plan} />
            ))}
          </Row>
        )}
      </Col>
    </>
  );
};

export default NewPlansWidget;
