import { useQuery } from "@tanstack/react-query";
import MediumPieCard from "components/widgets/Dashboard/MediumPieCard";
import { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Loader from "react-loaders";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import { useLocation } from "react-router-dom";
import dashboard from "utils/api/dashboard";
import { graphColors } from "utils/constants";
import PlanStatus from "utils/enums/PlanStatus";
import i18n from "utils/i18n/config";

type Props = {};

function totalPlansPieParser({ total, students }: any): any {
  const result: any = {
    plans: 0,
    students: 0,
    amount: 0,
    totalAccepted: 0,
    totalPlans: [
      {
        name: "Pending",
        value: 0,
        color: "#FDB022",
      },
      {
        name: "Accepted",
        value: 0,
        color: "#1570EF",
      },
      {
        name: "Rejected",
        value: 0,
        color: "#F04438",
      },
    ],
    generatedRevenue: [
      {
        name: i18n.t("plans:fi_cut"),
        value: 0,
        color: "#1570EF",
      },
      {
        name: i18n.t("plans:ruba_cut"),
        value: 0,
        color: "#53B1FD",
      },
    ],
  };
  const pendingStatus: any = [
    PlanStatus.INCOMPLETE,
    PlanStatus.PENDING_DOCUMENTS,
    PlanStatus.ARCHIVED,
    PlanStatus.PENDING_RUBA,
    PlanStatus.MISSING_DOCUMENTS_RUBA,
    PlanStatus.PENDING_QUOTATION,
    PlanStatus.PENDING_FI,
    PlanStatus.MISSING_DOCUMENTS_FI,
    PlanStatus.PENDING_CINET,
    PlanStatus.PENDING_SIGNATURE,
  ];
  const acceptedStatus: any = [
    PlanStatus.PENDING_RECEIPT,
    PlanStatus.PENDING_PAYMENT,
    PlanStatus.PENDING_PAYMENT_SCHOOL,
    PlanStatus.PENDING_INVOICE,
    PlanStatus.PENDING_RUBA_TID,
    PlanStatus.PENDING_RUBA_SCHOOL_TID,
    PlanStatus.ACTIVE,
    PlanStatus.COMPLETE,
  ];

  const rejectedStatus: any = [
    PlanStatus.REJECTED_RUBA,
    PlanStatus.REJECTED_FI,
  ];
  total.forEach((item: any) => {
    result.plans += item.plans;
    result.amount += item.amount;
    if (pendingStatus.includes(item.status)) {
      result.totalPlans[0].value += item.plans;
    }
    if (acceptedStatus.includes(item.status)) {
      result.generatedRevenue[0].value += item.fiRevenue;
      result.generatedRevenue[1].value += item.rubaRevenue;
      result.totalPlans[1].value += item.plans;
      result.totalAccepted += item.plans;
    }
    if (rejectedStatus.includes(item.status)) {
      result.totalPlans[2].value += item.plans;
    }
  });
  result.students = students;

  return result;
}

const AdminTotal = (props: Props) => {
  const [filter, setFilter] = useState<any>([]);
  const [filtered, setFiltered] = useState({
    plans: 0,
    students: 0,
    amount: 0,
    totalPlans: [],
    totalAccepted: [],
    generatedRevenue: [],
  });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryParamsObject = Object.fromEntries(queryParams.entries());

  const { data, isFetching } = useQuery(
    ["dashboard", "admin-total", queryParamsObject],
    () => dashboard.adminTotal(queryParamsObject),
    {
      placeholderData: [],
      onSuccess: (data) => {
        setFiltered(totalPlansPieParser(data));
      },
    }
  );

  useEffect(() => {
    if (filter.length > 0) {
      setFiltered(totalPlansPieParser(data));
    }
  }, [data, filter]);

  const { t } = useTranslation(["plans", "common", "schools"]);
  return (
    <>
      <Col lg={12} className="mb-3">
        <LoadingOverlayWrapper
          active={isFetching}
          styles={{
            overlay: (base) => ({
              ...base,
              background: "#fff",
              opacity: 0.5,
            }),
          }}
          spinner={<Loader active type="ball-pulse" />}
        >
          <Card>
            <Card.Body className="p-4">
              <Row>
                <Col
                  lg={6}
                  className="fs-6 d-flex justify-content-between text-dark fw-bold mb-3 mb-sm-5"
                >
                  <p>{t("common:total_summary")}</p>
                </Col>
                {/* <Col lg={6} className="text-end">
                  Filter 🔜
                </Col> */}
              </Row>
              <Row>
                <Col lg={3} className="">
                  <p className="text-graycool fs-7 mb-2">{t("total_plans")}</p>
                  <h3 className="text-dark fw-bold">{filtered.plans}</h3>
                </Col>
                <Col lg={3} className="">
                  <p className="text-graycool fs-7 mb-2">{t("complete")}</p>
                  {console.log(filtered)}
                  <h3 className="text-dark fw-bold">{filtered.totalAccepted}</h3>
                </Col>
                <Col lg={3} className="">
                  <p className="text-graycool fs-7 mb-2">
                    {t("schools:total_students")}
                  </p>
                  <h3 className="text-dark fw-bold">{filtered.students}</h3>
                </Col>
                <Col lg={3} className="">
                  <p className="text-graycool fs-7 mb-2">{t("total_amount")}</p>
                  <h3 className="text-success fw-bold">
                    {filtered.amount.toLocaleString(undefined, {
                      maximumFractionDigits: 0,
                    })}
                    <sup className="fs-5"> {t("common:kd")}</sup>
                  </h3>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </LoadingOverlayWrapper>
      </Col>
    </>
  );
};

export default AdminTotal;
