import { User } from "utils/api/auth";
import PlanStatus from "utils/enums/PlanStatus";
import ReserveStatus from "utils/enums/ReserveStatus";
import Role from "utils/enums/Role";

const actionablePlan = (user: User, plan: any) => {
  if (user.role_id === Role.ADMIN) return true;
  if (user.role_id === Role.MODERATOR) return true;
  if (user.role_id === Role.USER)
    return [
      PlanStatus.MISSING_DOCUMENTS_RUBA,
      PlanStatus.MISSING_DOCUMENTS_FI,
      PlanStatus.PENDING_CINET,
      PlanStatus.PENDING_SERVICE_FEE,
      PlanStatus.PENDING_SIGNATURE,
      PlanStatus.PENDING_USER_APPROVAL,
    ].includes(plan.status);
  if (user.role_id === Role.SCHOOL)
    return [
      PlanStatus.PENDING_REGISTRATION,
      PlanStatus.PENDING_QUOTATION,
      PlanStatus.PENDING_RECEIPT,
      PlanStatus.PENDING_PAYMENT_SCHOOL,
      PlanStatus.PENDING_INVOICE,
      PlanStatus.PENDING_RUBA,
      PlanStatus.MISSING_DOCUMENTS_RUBA,
      PlanStatus.REJECTED_RUBA,
      PlanStatus.PENDING_FI,
      PlanStatus.MISSING_DOCUMENTS_FI,
      PlanStatus.REJECTED_FI,
      PlanStatus.PENDING_SIGNATURE,
      PlanStatus.PENDING_SCHOOL_APPROVAL
    ].includes(plan.status);
  if (user.role_id === Role.FI)
    return [
      PlanStatus.PENDING_FI,
      PlanStatus.PENDING_SIGNATURE,
      PlanStatus.PENDING_PAYMENT,
      PlanStatus.PENDING_RUBA_TID,
    ].includes(plan.status);
  return false;
};

export default actionablePlan;
