import client from "../api";

export function generatedRevenue(filter?: any): Promise<any> {
  return client.get("dashboard/generated-revenue", {});
}

export function onboardedSchools(filter?: any): Promise<any> {
  return client.get("dashboard/onboarded-schools", {
    params: { activation_date: filter },
  });
}

export function topSchools(): Promise<any> {
  return client.get("dashboard/top-schools", {});
}

export function adminPendingActions(queryParamsObject: any): Promise<any> {
  return client.get("dashboard/admin-pending", { params: queryParamsObject });
}

export function adminStats(queryParamsObject: any): Promise<any> {
  return client.get("dashboard/admin-stats", { params: queryParamsObject });
}

export function adminReserveStats(queryParamsObject: any): Promise<any> {
  return client.get("dashboard/admin-reserve-stats", { params: queryParamsObject });
}


export function fiStats(): Promise<any> {
  return client.get("dashboard/fi-stats");
}

export function adminGMV(queryParamsObject: any): Promise<any> {
  return client.get("dashboard/admin-gmv", { params: queryParamsObject });
}

export function adminTotal(queryParamsObject: any): Promise<any> {
  return client.get("dashboard/admin-total", { params: queryParamsObject });
}

export function adminTotalReserve(queryParamsObject: any): Promise<any> {
  return client.get("dashboard/admin-total-reserve", { params: queryParamsObject });
}

export function studentsDemographics(): Promise<any> {
  return client.get("dashboard/students-demographics", {});
}

export function schoolPaymentHistoryProgress(reserveId: string): Promise<any> {
  return client.get("dashboard/school-payment-history-progress/" + reserveId, {});
}

export function schoolPaymentsSummary(reserveId: string): Promise<any> {
  return client.get("dashboard/school-payments-summary/" + reserveId, {});
}

async function schoolReserveProgress(reserveId: string): Promise<any> {
  return client.get(`dashboard/school-reserve-progress/${reserveId}`);
}

async function adminServiceFeeStats(school_id: string | null): Promise<any> {
  return client.get(`dashboard/admin-service-fee-stats` + (school_id ? "?school_id=" + school_id : ""));
}

async function adminStudentsStats(school_id: string | null): Promise<any> {
  return client.get(`dashboard/admin-students-stats` + (school_id ? "?school_id=" + school_id : ""));
}

async function filterables(): Promise<any> {
  return client.get(`dashboard/filterables`);
}

const dashboard = {
  adminTotal,
  adminTotalReserve,
  generatedRevenue,
  onboardedSchools,
  topSchools,
  adminPendingActions,
  adminStats,
  fiStats,
  adminGMV,
  studentsDemographics,
  schoolPaymentHistoryProgress,
  schoolPaymentsSummary,
  schoolReserveProgress,
  adminReserveStats,
  adminServiceFeeStats,
  adminStudentsStats,
  filterables
};

export default dashboard;
