import { useTranslation } from "react-i18next";
import ReserveStatus from "utils/enums/ReserveStatus";
import Role from "utils/enums/Role";
import {
  statusSpaceToUnderScore
} from "utils/functions/utilities";
import { useUser } from "utils/hooks/auth";
import StatusPerUser from "./rules";


function ReserveStatusRenderer(props) {
  const { t } = useTranslation(["plans", "common"]);
  const { data: user } = useUser({});
  const { status } = props;

  if (!status) return <></>;

  const newStatus = StatusPerUser({ role: user?.role_id ?? Role.ADMIN })[status];

  let color = "primary";
  switch (newStatus) {
    case ReserveStatus.PENDING_APPROVAL:
    case ReserveStatus.PENDING_PAYMENT:
      // color = "warning";
      break;
    case ReserveStatus.REJECTED:
      // color = "danger";
      break;
    case ReserveStatus.ACTIVE:
    case ReserveStatus.COMPLETE:
      // color = "success";
      break;
    default:
      break;
  }

  return (
    <div className="widget-content-right">
      <div className={`badge bg-${color}`}>
        🏫 {t(statusSpaceToUnderScore(newStatus ?? status))}
      </div>
    </div>
  );
}

export default ReserveStatusRenderer;
